import React from "react"
import { Link } from "react-router-dom"

const Footer = ({style}) => {
    return (
        <div style={style} className='footer'>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none' }} target='_blank' to='privacy-notice'>Privacy notice</Link>
          </div>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none' }} target='_blank' to='/refund-policy'>Refund policy</Link>
          </div>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none' }} target='_blank' to='/terms-of-service'>Terms of service</Link>
          </div>
          {/* <div className='footer-inside'>
            <a style={{ textDecoration: 'none', color: 'black', height: 25 }} href='https://www.youtube.com/watch?v=JN4z1mBXn6s&lc=Ugy6ACgYQA7f_cJOfNB4AaABAg'><svg style={{ height: 25 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.57  20" focusable="false"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.57 20" preserveAspectRatio="xMidYMid meet"><g><path d="M27.9727 3.12324C27.6435 1.89323 26.6768 0.926623 25.4468 0.597366C23.2197 2.24288e-07 14.285 0 14.285 0C14.285 0 5.35042 2.24288e-07 3.12323 0.597366C1.89323 0.926623 0.926623 1.89323 0.597366 3.12324C2.24288e-07 5.35042 0 10 0 10C0 10 2.24288e-07 14.6496 0.597366 16.8768C0.926623 18.1068 1.89323 19.0734 3.12323 19.4026C5.35042 20 14.285 20 14.285 20C14.285 20 23.2197 20 25.4468 19.4026C26.6768 19.0734 27.6435 18.1068 27.9727 16.8768C28.5701 14.6496 28.5701 10 28.5701 10C28.5701 10 28.5677 5.35042 27.9727 3.12324Z" fill="#FF0000" /><path d="M11.4253 14.2854L18.8477 10.0004L11.4253 5.71533V14.2854Z" fill="white" /></g></svg></svg></a>
          </div> */}
          <div className='footer-inside'>
            <a style={{ height: 25 }} href='https://t.me/artschoolmovement'><img src='/images/tg.png' height={25} alt='telegram-icon' /></a>
          </div>
          <div className='footer-inside'>
            <a style={{ height: 25 }} href="mailto:hi@artschoolmovement.com"><img src='/images/email.png' alt='email-icon' height={25} /></a>
          </div>
        </div>
    )
}
export default Footer