import React from 'react';
import Footer from './components/Footer';
import { Link } from 'react-router-dom';

function TermsOfService() {

  return (
    <div className='refund-container' style={{ display: 'grid', alignItems: 'center', justifyContent: 'center', gap: 30 }}>
      <Link to='/' className='main-refund' style={{ flexDirection: 'row', textDecoration: 'none', justifyContent: 'flex-start', gap: 20, padding: 20, width: 'fit-content', minWidth: 'fit-content' }}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18.4961" height="14.8145">  <g>   <rect height="14.8145" opacity="0" width="18.4961" x="0" y="0" />   <path d="M0 7.40234C0 7.64648 0.107422 7.87109 0.302734 8.05664L6.78711 14.5312C6.98242 14.7168 7.1875 14.8047 7.42188 14.8047C7.90039 14.8047 8.28125 14.4531 8.28125 13.9648C8.28125 13.7305 8.19336 13.4961 8.03711 13.3496L5.84961 11.123L1.98242 7.59766L1.77734 8.07617L4.92188 8.27148L17.2754 8.27148C17.7832 8.27148 18.1348 7.91016 18.1348 7.40234C18.1348 6.89453 17.7832 6.5332 17.2754 6.5332L4.92188 6.5332L1.77734 6.72852L1.98242 7.2168L5.84961 3.68164L8.03711 1.45508C8.19336 1.29883 8.28125 1.07422 8.28125 0.839844C8.28125 0.351562 7.90039 0 7.42188 0C7.1875 0 6.98242 0.078125 6.76758 0.292969L0.302734 6.74805C0.107422 6.93359 0 7.1582 0 7.40234Z" fill="black" />  </g> </svg>
        <h2 style={{ textDecoration: 'none', color: 'black' }}>
          Go back to home
        </h2>
      </Link>
      <div className='main-refund' style={{ marginTop: 20 }}>
        <h1>Terms of Service</h1>
        <p><strong>Effective since: 16/09/2024</strong></p>
        <p>Welcome to artschoolmovement.com. These Terms of Service govern your use of our website and services, and by accessing or using our services, you agree to be bound by these terms and all terms incorporated by reference. If you do not agree to all of these terms, do not use our services.</p>

        <h2>1. Services Description</h2>
        <p>We provide online training in graphic design, focusing mainly on the use of software from the Adobe suite. We provide an Adobe subscription to our students, through an Adobe Authorized Reseller specializing in educational subscriptions, with whom we have established a partnership.</p>

        <h2>2. Eligibility</h2>
        <p>You must be at least 18 years old to use our services. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract with us.</p>

        <h2>3. Enrollment Terms</h2>
        <ul>
          <li><strong>Automatic Renewal:</strong> Your enrollment will automatically renew at the end of each period, unless you cancel it before the renewal date.</li>
          <li><strong>One-Time Payment:</strong> If you choose not to activate automatic renewal, your enrollment will end at the conclusion of the enrollment period with no further charges.</li>
        </ul>

        <h2>4. Payment</h2>
        <p>All payments are processed securely. You agree to pay the specified fees at the time of your purchase, and you acknowledge that prices are subject to change without notice.</p>

        <h2>5. Cancellation and Refund Policy</h2>
        <p>You can cancel your enrollment at any time. For details on refunds, please refer to our <Link to="/refund-policy">Refund Policy</Link>.</p>

        <h2>6. Intellectual Property Rights</h2>
        <p>Adobe, Adobe Substance 3D, Adobe Creative Cloud are either registered trademarks or trademarks of Adobe in the United States and/or other countries. You are granted a limited, non-exclusive, non-transferable license to access and use the Adobe products for your personal or business use, subject to the terms of this agreement.</p>

        <h2>7. Compliance with Laws</h2>
        <p>You agree to comply with all applicable laws and regulations in connection with your use of our services. This includes, but is not limited to, compliance with Chinese regulations and Adobe's licensing agreements.</p>

        <h2>8. Limitation of Liability</h2>
        <p>We shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services.</p>

        <h2>9. Changes to Terms of Service</h2>
        <p>We reserve the right to modify these terms at any time. We will provide notice of these changes by updating the effective date at the top of the terms. Your continued use of our services after such changes have been notified will constitute your consent to those changes.</p>

        <h2>10. Contact Information</h2>
        <p>If you have any questions about these Terms of Service, please contact us at tos@artschoolmovement[dot]com.</p>

        <p>By using our services, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.</p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;